import React from "react"
import ReactDOM from "react-dom"
import Footer from "../../components/Footer"

export default function initializer() {
  const root = document.getElementById("misago-footer")
  ReactDOM.render(<Footer />, root)
}

misago.addInitializer({
  name: "component:misagoFooter",
  initializer: initializer,
  after: "store",
})
