import React, { Component } from "react"
import { withRouter } from "react-router"
import DatePicker from "react-datepicker"
import InputMask from "react-input-mask"
import moment from "moment"
import posting from "../../services/posting"
import Button from "../button"
import { Toolbar, ToolbarItem, ToolbarSection, ToolbarSpacer } from "../Toolbar"
import ThreadsCategoryPicker from "./ThreadsCategoryPicker"
import ThreadsListPicker from "./ThreadsListPicker"
import ThreadsToolbarModeration from "./ThreadsToolbarModeration"
import { parseQueryString } from "./utils"
import "react-datepicker/dist/react-datepicker.css"

moment.locale("ru")

const formatDate = "DD.MM.YYYY"
const ThreadsFilterss = (props) => {
  const router = props?.router
  const queryObject = parseQueryString(window.location.search)

  const handleAddQueryParams = (value) => {
    router.replace({
      pathname: window.location.pathname,
      query: {
        ...queryObject,
        ...(value ? { ordering: value } : {}),
      },
    })
  }

  const onChange = (value) => {
    handleAddQueryParams(value)
    props?.onChangeOrdering(value)
  }

  return (
    <div
      className="toolbar-item mb-2"
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "16px",
      }}
    >
      <DateRangePicker
        onChange={(type, value) => {
          const formattedDate = value
            ? moment(value, formatDate).format(formatDate)
            : null

          router.replace({
            pathname: window.location.pathname,
            query: {
              ...queryObject,
              [type]: formattedDate,
            },
          })
          props?.onChangeDate(type, formattedDate)
        }}
        startDate={
          queryObject?.last_post_on__gte
            ? moment(queryObject?.last_post_on__gte, formatDate)
            : null
        }
        endDate={
          queryObject?.last_post_on__lte
            ? moment(queryObject?.last_post_on__lte, formatDate)
            : null
        }
      />

      <div className="">
        <select
          className={"form-control input-md-size"}
          disabled={false}
          onChange={(event) => onChange(event?.target?.value)}
          value={queryObject.ordering}
        >
          <option value={""}>Выберите сортировку</option>
          <option value={"-replies"}>Сначала популярные</option>
          <option value={"replies"}>Сначала менее популярные</option>
        </select>
      </div>
    </div>
  )
}
const ThreadsFilters = withRouter(ThreadsFilterss)

const ThreadsToolbar = ({
  api,
  baseUrl,
  category,
  categories,
  categoriesMap,
  topCategory,
  topCategories,
  subCategory,
  subCategories,
  list,
  lists,
  threads,
  addThreads,
  startThread,
  freezeThread,
  updateThread,
  deleteThread,
  selection,
  moderation,
  route,
  user,
  disabled,
  onChangeOrdering,
  ordering,
  onChangeDate,
  dateGte,
  dateLte,
  hasFilters,
}) => (
  <Toolbar>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {topCategories.length > 0 && (
          <ToolbarSection>
            <ToolbarItem>
              <ThreadsCategoryPicker
                allItems={pgettext("threads list nav", "All categories")}
                parentUrl={list.path}
                category={topCategory}
                categories={topCategories}
                list={list}
              />
            </ToolbarItem>
            {topCategory && subCategories.length > 0 && (
              <ToolbarItem>
                <ThreadsCategoryPicker
                  allItems={pgettext("threads list nav", "All subcategories")}
                  parentUrl={topCategory.url.index}
                  category={subCategory}
                  categories={subCategories}
                  list={list}
                />
              </ToolbarItem>
            )}
          </ToolbarSection>
        )}
        {lists.length > 1 && (
          <ToolbarSection className="hidden-xs">
            <ToolbarItem>
              <ThreadsListPicker baseUrl={baseUrl} list={list} lists={lists} />
            </ToolbarItem>
          </ToolbarSection>
        )}
        <ToolbarSpacer />
        {!!user.id && (
          <ToolbarSection>
            <ToolbarItem>
              <Button
                className="btn-accent btn-block btn-lg"
                disabled={disabled}
                onClick={() => {
                  posting.open(
                    startThread || {
                      mode: "START",

                      config: misago.get("THREAD_EDITOR_API"),
                      submit: misago.get("THREADS_API"),

                      category: category.id,
                    }
                  )
                }}
              >
                <span className="material-icon">chat</span>
                {pgettext("threads list nav", "Start thread")}
              </Button>
            </ToolbarItem>
            {!!moderation.allow && (
              <ToolbarItem shrink>
                <ThreadsToolbarModeration
                  api={api}
                  categories={categories}
                  categoriesMap={categoriesMap}
                  threads={threads.filter(
                    (thread) => selection.indexOf(thread.id) !== -1
                  )}
                  addThreads={addThreads}
                  freezeThread={freezeThread}
                  updateThread={updateThread}
                  deleteThread={deleteThread}
                  selection={selection}
                  moderation={moderation}
                  route={route}
                  user={user}
                  disabled={disabled}
                />
              </ToolbarItem>
            )}
          </ToolbarSection>
        )}
      </div>
      {hasFilters && (
        <ThreadsFilters
          onChangeOrdering={onChangeOrdering}
          ordering={ordering}
          onChangeDate={onChangeDate}
          dateGte={dateGte}
          dateLte={dateLte}
        />
      )}
    </div>
  </Toolbar>
)

export default ThreadsToolbar

class DateRangePicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      startDate: props?.startDate || null,
      endDate: props?.endDate || null,
    }
  }

  handleStartDateChange = (date) => {
    this.setState((prevState) => {
      if (prevState.endDate && date > prevState.endDate) {
        return { startDate: date, endDate: null }
      }
      this.props?.onChange("last_post_on__gte", date)
      return { startDate: date }
    })
  }

  handleEndDateChange = (date) => {
    this.props?.onChange("last_post_on__lte", date)
    this.setState({ endDate: date })
  }

  render() {
    const { startDate, endDate } = this.state

    return (
      <div
        style={{
          display: "flex",
          gap: "8px",
        }}
      >
        <DatePicker
          selected={startDate}
          onChange={this.handleStartDateChange}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          customInput={
            <ExampleCustomInput
              name="gte"
              className={"input-md-size"}
              onClear={() => this.handleStartDateChange(null)}
              label="с"
            />
          }
        />

        <DatePicker
          selected={endDate}
          onChange={this.handleEndDateChange}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          customInput={
            <ExampleCustomInput
              name="lte"
              className={"input-md-size"}
              onClear={() => this.handleEndDateChange(null)}
              label="до"
            />
          }
        />
      </div>
    )
  }
}

const ExampleCustomInput = ({
  value,
  label,
  onClick,
  className,
  onClear,
  ...props
}) => (
  <div
    className={"input-md-size"}
    style={{
      display: "flex",
      gap: "10px",
      alignItems: "center",
    }}
    onClick={onClick}
  >
    <span className="">{label}</span>
    <InputMask
      style={{}}
      mask={"99.99.9999"}
      maskChar={null}
      value={value}
      {...props}
    />
    {value && (
      <span
        onClick={(e) => {
          e.stopPropagation()
          onClear?.()
        }}
        className="material-icon"
      >
        close
      </span>
    )}
  </div>
)
